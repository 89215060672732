<template>
  <div class="rent-booking-list animatedBox">
    <div class="container fluid">
      <filter-panel
        v-model="isFilterActive"
        useToggle
        :isLoading="isFilterLoading"
        :loadingItems="1"
        @filter="filterData"
        @clear="clearFilter"
      >
        <div class="p-2">
          <fd-select
            v-model="filter['status:id']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Status"
            :options="statusOptions"
            :optionLabel="(val) => val.name"
            :optionValue="(val) => val.id"
          >
          </fd-select>
        </div>
      </filter-panel>
      <!-- ============================ Table ============================ -->
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="bookingTableColumns"
          :rows="bookingTableData"
          :totalRows="bookingTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false,
            initialSortBy: { field: 'id', type: 'desc' }
          }"
          @on-row-click="toBookingDetail"
        >
          <div slot="emptystate">
            <no-data message="There is no data"></no-data>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'currentStatus'">
              <booking-status :status="props.row.status"></booking-status>
              <chip
                v-if="getIsOTRExpired(props.row) && isBookingActive(props.row)"
                class="bg-danger fg-white ml-1"
              >
                OTR Expired
              </chip>
            </span>
            <property-summary
              v-if="props.column.field == 'propertySummary'"
              :property="props.row.property"
              address
            >
            </property-summary>
            <span
              v-else-if="props.column.field == 'OTRPrice'"
              class="font-bold"
            >
              RM
              {{ numberWithCommas(parseInt(props.row.offerToRent.rentPrice)) }}
            </span>
            <span>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { rentBookingStatus } from "@/enums";
import { rentBooking as rentAPI } from "@/api";
import { numberWithCommas } from "@/utils/string";

export default {
  name: "BookingList",
  mixins: [vueGoodTable],
  components: {
    PropertySummary: () =>
      import("@/components/GlobalComponents/Shared/Property/PropertySummary"),
    BookingStatus: () => import("@/components/RentBooking/BookingStatus"),
    Chip: () => import("@/components/GlobalComponents/Chip")
  },
  data() {
    return {
      bookingTableData: [],
      bookingTablePagination: {
        total: 0
      },
      bookingTableColumns: [
        {
          label: "Status",
          field: "currentStatus",
          hidden: false
        },
        {
          label: "Tenant",
          field: "tenant.name"
        },
        {
          label: "Property",
          field: "propertySummary"
        },
        {
          label: "Price",
          field: "OTRPrice"
        },
        {
          label: "Date of Booking",
          field: "createdAt",
          formatFn: (data) => this.$dayjs(data).format("DD-MM-YYYY")
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      isFilterLoading: false,
      filter: {
        "status:id": ""
      },
      statusOptions: [],
      rentBookingStatus,

      numberWithCommas
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await Promise.all([this.getAllBookingList(), this.getStatus()]);
    },
    getIsOTRExpired(booking) {
      let today = this.$dayjs();
      let expiryDate = this.$dayjs(booking.confirmOTRBefore, "YYYY-MM-DD");
      let expired = expiryDate.diff(today, "days", true) <= 0;

      return expired;
    },
    isBookingActive(booking) {
      booking.status.id !== rentBookingStatus.OTR_COMPLETED &&
        booking.status.id !== rentBookingStatus.RENT_CANCELLED &&
        booking.status.id !== rentBookingStatus.RENT_COMPLETED;
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },
    toBookingDetail(event) {
      this.$router.push({
        name: "RentBookingDetail",
        params: { id: event.row.id }
      });
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllBookingList();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllBookingList();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());

      this.getAllBookingList();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllBookingList();
    },
    // ========================== API Related =========================
    async getAllBookingList() {
      this.$store.commit("setIsLoading", true);
      try {
        // Remove empty string from filter
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await rentAPI.getBookings({
          queries: {
            ...filteredParam
          },
          page: this.tableParams.page,
          perPage: this.tableParams.perPage
        });

        this.bookingTableData = this._.cloneDeep(data.data);
        this.bookingTablePagination = this._.cloneDeep(data.meta.pagination);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    async getStatus() {
      try {
        this.isFilterLoading = true;
        let data = await rentAPI.getStatus();
        this.statusOptions = data;
        this.isFilterLoading = false;
      } catch (error) {
        this.isFilterLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text:
            "Failed to fetch status for rental booking. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
